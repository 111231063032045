/*COPIED FROM CONSUMER SITE. SHOULD BE KEPT UP TO DATE WITH THE CORRESPONDING CSS*/

.cardContainer {    
    background-color: lightgrey;
    border-radius: 8px;    
    width:150px;
    height:200px;
    border:1px solid grey;
    transition:all 0.3s;
    margin: 7px;
}
.cardContainerDesktop {
    background-color: lightgrey;
    border-radius: 16px;    
    width:150px;
    height:186px;
    border:1px solid grey;
    transition:all 0.3s;;
    margin: 7px;
}

.cardContainer:hover {
    cursor:pointer;
    box-shadow: 2px 2px 5px grey;
    
}
.cardContainerDesktop:hover {
    cursor:pointer;
    box-shadow: 2px 2px 5px grey;
    
}

.cardContainerDesktop .cardTopImage {
    border-radius: 16px 16px 0px 0px;
}
.cardTopImage{        
    border-radius: 8px 8px 0px 0px;
    max-width:100%;    
}

.cardContainerDesktop .cardTopContainer {
    border-radius: 16px 16px 0px 0px;
}
.cardTopContainer{
    background-color: #F1F1F1;
    border-radius: 8px 8px 0px 0px;    
    height:50%;
}

.cardContainerDesktop .cardBottomContainer {
    border-radius: 0px 0px 16px 16px;
}

.cardBottomContainer {    
    border-radius: 0px 0px 8px 8px;
    color: white;
    height:50%;
}
.cardContainerDesktop .cardTitle {
    padding:20px;    
}
.cardTitle {
    padding: 12px;
    display: block;
    text-align: left;
}


.noImage {
    background:#fff;    
    animation: changecolor 2s infinite alternate;
    display:flex;
    height:100%;
    align-items:center;
    justify-content: center;

}

@keyframes changecolor {
    from {background-color: white;}
    to {background-color: gainsboro;}
  }
