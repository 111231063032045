
div .saveButton {
    background: #299B60 no-repeat left;
    min-width: 250px;
    padding: 10px;
    margin: 10px;    
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    transition:0.3s background-color, 0.3 color;
}

div .saveButton:disabled {
    background:gainsboro;
    color:grey;
}

div .cancelButton {    
    color: rgb(38, 153, 95);
    padding: 10px;
    margin: 10px;    
}

.errorTab {
    color:red;
}

.confirmDeleteModal {
    margin:16px 0;
    padding:16px 0;
}
.confirmDeleteModalTitle {
    color:#26995F;
    border-bottom: 1px solid #26995F;
    margin-bottom:8px;
}
.confirmNSOReconciliation {
    padding:16px 0;
}

.dropzoneWrapper {
    background-color: white;
    border:3px dotted rgb(168, 168, 218);
    margin-top:8px;
    padding:16px;
    transition: background 0.3s;
    display:flex;
    align-items: center;
    justify-content: center;;
}
.dropzoneWrapper:hover {
    cursor:pointer;
    border:none;
    padding:19px;
    background: rgb(204, 236, 196); 
}
.neighborhoodDropzoneWrapper {
    margin-bottom:12px;
}
.neighborhoodDropzoneWrapper > div:first-of-type {
    margin-right:20px;
}
.infoIcon {
    color: grey;
    width: 24px;
}