
.launchProviderResult {
    box-shadow: 0px 0px 5px green;
    padding:10px;
}
.error {

}
.success {

}
.launchProviderResult.error {
    box-shadow: 0px 0px 5px red;
}
.launchProviderResult .resultCard {
    padding:10px;
    margin:10px;
    box-shadow: 0px 0px 2px gainsboro;
}

.launchProviderResult .resultCard.success {    
    box-shadow:0px 0px 3px green;
}

.launchProviderResult .resultCard.error {    
    box-shadow:0px 0px 3px red;
}
.downloadResults {
    display:inline-flex;
    align-items: center;
    color: green;
    border:1px solid green;
    width:100px;
    padding:6px;
    margin-right:8px;
    border-radius:4px;
}
.downloadResults span {
    padding-left:4px;
}

div .saveButton {
    background: #299B60 no-repeat left;
    min-width: 250px;
    padding: 10px;
    margin: 10px;    
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    transition:0.3s background-color, 0.3 color;
}
div .saveButton:first-of-type {
    margin-left:0;
    
}

div .saveButton:disabled {
    background:gainsboro;
    color:grey;
}

.confirmDeleteModal {
    margin:16px 0;
    padding:16px 0;
}
.confirmDeleteModalTitle {
    color:#26995F;
    border-bottom: 1px solid #26995F;
    margin-bottom:8px;
}
.confirmNSOReconciliation {
    padding:16px 0;
}