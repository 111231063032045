
.serviceInstancesGrid {
    padding-top:10px;    
}
.serviceInstancesGrid button {
    margin-bottom:10px;
}
.confirmDeleteModal {
    padding:10px;
    padding-left:0;
    margin-bottom:20px;
}

.confirmDeleteModalTitle {
    color:#26995F;
    border-bottom: 1px solid #26995F;
    margin-bottom:8px;
}