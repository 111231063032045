
button.cancelButton {
    color: rgb(38, 153, 95);    
    min-width: 150px;
    padding:9px;
    transition:0.3s background-color, 0.3 color;
    font-weight: bold;
    height:40px;
    border: 1px solid rgb(38, 153, 95);
}


button.cancelButton:hover {
    background:rgb(29, 119, 74);
    color: #FFF;
}