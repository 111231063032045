.formGridContainer {
    padding:20px;
}
.serviceTypeHeader {
    margin-top:20px;
}
.serviceTypeWrapper:first-of-type {
    margin-top:20px;
}
.serviceTypeWrapper {    
    border:1px solid gainsboro;    
    padding:8px;
    margin:20px 0;
}

.dropzoneWrapper {
    background-color: white;
    border:3px dotted rgb(168, 168, 218);
    margin-top:8px;
    padding:16px;
    transition: background 0.3s;
    display:flex;
    align-items: center;
    justify-content: center;;
}
.dropzoneWrapper:hover {
    cursor:pointer;
    border:none;
    padding:19px;
    background: rgb(204, 236, 196); 
}
.serviceTypeDropzoneWrapper {
    margin-bottom:12px;
}
div .rating{
    margin-top:14px;
}
div .uploadErrorMessage {
    margin-top:4px;
    background-color: rgba(255, 0, 0, 0.281);
}

.serviceTypeCard {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 50px;
    padding: 4px;
    border: solid black 1px;
    text-align: center;
    margin: 4px;
    background-color: white;
    transition: background 0.3s;
}

.serviceTypeCardDragging {
    background-color: rgb(142, 142, 241);
}