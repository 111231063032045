
.dropzoneWrapper {
    background-color: white;
    border:3px dotted rgb(168, 168, 218);
    padding:16px;
    transition:  background 0.3s;
    display:flex;
    align-items: center;
    justify-content: center;
}
.dropzoneWrapper:hover {
    cursor:pointer;
    border:none;
    padding:19px;
    background: rgb(204, 236, 196); 
}
div .uploadErrorMessage {
    margin-top:4px;
    background-color: rgba(255, 0, 0, 0.281);
}