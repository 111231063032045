
div .primaryButton {
    background: #299B60 no-repeat left;
    min-width: 150px;
    height:40px;
    padding: 10px;
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    transition:0.3s background-color, 0.3 color;
}

div .primaryButtonText {
    min-width: 150px;
    padding: 10px;
    height:40px;
    border-radius: 5px;
    color: #299B60;
    font-weight: bold;
    transition:0.3s background-color, 0.3 color;
}

button.primaryButtonText:hover {
    background:#224E4E;
    color: #ffffff;
}

button.primaryButton:hover {
    background:#224E4E;
}
div .primaryButton:disabled {
    background:gainsboro;
    color:grey;
}

.primaryButton.outlined {
    background: none;
    border: 1px solid #299B60;
    color: #299B60;
}

button.primaryButton.outlined:hover {
    background:#224E4E;
    color:#FFF;
}
button.primaryButton.outlined:disabled {
    border: 1px solid gainsboro;
    background:gainsboro;
    color:grey;
}

div .cancelButton {    
    height:40px;
    color: rgb(38, 153, 95);
    padding: 10px;
}