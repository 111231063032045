
.header {
    font-weight: bold;
    font-size: 19px;
    color: #26995F;
    margin-bottom: 0;
}
.headerWrapper {
    width:100%;
    border-bottom: 1px solid #26995F;
    padding-bottom:10px;
}