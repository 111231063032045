.modalParent {
    display:flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
}

.modalTitle {
    font-size:1.3em;
    font-weight:500;
    margin-bottom:8px;
}