
.loadingDormant {
    width:100%;
}
.loadingContent > *:first-child {        
    opacity:0.2;
}
.loading {    
    position:fixed;
}
.loadingWrapper {    
    height: 100vh;    
    width: 100vw;    
    position: fixed;    
    top: 0;        
    transition:all 0.3s;
}
.loadingAbsolute {
    position:absolute;
}