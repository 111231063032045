.tabPanel {
    width:100%;
}

.tabDivider {
    margin: 32px 16px 0px 16px;
    width: 100%;
}

.tabBackground{
    margin: 0 16px 0 16px;
    padding-bottom: 8px;
}

.saveButton {
    margin-top: 40px;
}

div .saveButton:disabled {
    background:gainsboro;
    color:grey;
}

.errorTab {
    color:red;
}

.cardTitle {
    text-decoration: underline;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sectionTitle {
    margin-top: 20px; 
    margin-bottom: 20px;
}

.accordionTitle {
    text-decoration: underline;
}

.dropzoneWrapper {
    background-color: white;
    border:3px dotted rgb(168, 168, 218);
    margin-top:8px;
    padding:16px;
    transition:  background 0.3s;
    display:flex;
    align-items: center;
    justify-content: center;;
}

.dropzoneWrapper:hover {
    cursor:pointer;
    border:none;
    padding:19px;
    background: rgb(204, 236, 196); 
}

.summarySection {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 15px;
}

.summarySectionTitle {
    text-decoration: underline;
    margin-bottom: 10px;
}

.serviceOfferingCard {
    box-shadow: 0px 0px 2px grey;
}

.serviceOfferingCardContent {
    display:flex;
    flex-flow:column;
}

.addLabel {
    margin-right:4px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    color: #26995F;
}

.addButton {
    margin-left:4px;
}

div .customField {
    margin-bottom:64px;
    border:1px solid rgba(0.5,0.5,0.5,0.2);
    padding:16px;
    margin:16px;
}

.suggestionPillButton {
    background: #299B60 no-repeat left;
    padding: 10px;
    border-radius: 25px;
    color: #ffffff;
    font-weight: bold;
    font-size: small;
    transition:0.3s background-color, 0.3 color;
}

div .suggestionPillButton:disabled {
    background:gainsboro;
    color:grey;
}

button.suggestionPillButton:hover {
    background:#224E4E;
}

button.submitForApprovalButton {
    background: rgb(51, 5, 144) no-repeat left;
    padding: 10px;
    color: #ffffff;
    font-weight: bold;
    font-size: small;
    transition:0.3s background-color, 0.3 color;
}