
.flush {
    animation: flush 2s cubic-bezier(.36,.07,.19,.97) both;
}

.noflush {
    
}

@keyframes flush {
    0% {
        background:#DE3051;
        transform: rotate(0deg) scale(1,1);        
    }
    50% {
        
    }    
    100% {
        transform: rotate(1080deg) scale(0,0);
        
    }   
}