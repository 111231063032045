.dropzoneWrapper {
    background-color: white;
    border:3px dotted rgb(168, 168, 218);
    margin-top:8px;
    padding:16px;
    transition:  background 0.3s;
    display:flex;
    align-items: center;
    justify-content: center;;
}
.dropzoneWrapper:hover {
    cursor:pointer;
    border:none;
    padding:19px;
    background: rgb(204, 236, 196); 
}
.uploadInfoMessage {
    margin-top:8px;
}
.uploadErrorMessage {

}
.confirmModal {
 padding:20px 0;   
}

.customerUploadDeleteForm {
    margin-top:12px;
}