.quickSelectButton {
    background: #4646eb no-repeat left;
    padding: 10px;
    border-radius: 25px;
    color: #ffffff;
    font-weight: bold;
    font-size: small;
    transition:0.3s background-color, 0.3 color;
}

.cancelButton {
    min-width: 200px;
    padding: 10px;
    border-radius: 5px;
    transition:0.3s background-color, 0.3 color;
}