.serviceOfferingCard {
    box-shadow: 0px 0px 2px grey;
}
.serviceOfferingCardContent {
    display:flex;
    flex-flow:column;
}
.addLabel {
    margin-right:4px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    color: #26995F;
}
.addButton {
    margin-left:4px;
}
