.modalParent {
    display:flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
}

.modalTitleWrapper {
    margin-bottom:20px;
}
.modalTitle {
    font-size:1.3em;
    font-weight:500;    
}

.modalContent {
    padding-bottom:12px;
}