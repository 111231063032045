.tabPanel {
    width:100%;
}
.tabDivider {
    margin: 32px 16px 0px 16px;
    width: 100%;
}
.tabBackground{
    background: rgb(242, 243, 242,0.3);
    margin: 0 16px 0 16px;
    padding-bottom: 8px;
}

div .saveButton {
    background: #299B60 no-repeat left;
    min-width: 250px;
    padding: 10px;
    margin: 10px;    
    border-radius: 5px;
    color: #ffffff;
    font-weight: bold;
    transition:0.3s background-color, 0.3 color;
}

div .saveButton:disabled {
    background:gainsboro;
    color:grey;
}

div .cancelButton {    
    color: rgb(38, 153, 95);
    padding: 10px;
    margin: 10px;    
}

.errorTab {
    color:red;
}